import React from 'react'
import KeyValueForm from '../keyValueForm/KeyValueForm'
import { Button } from 'react-bootstrap'
import { gql } from 'apollo-boost'
import './ReadKeyValueForm.css'
import '../Forms.css'
import { useConfirmModal } from '../../../../hooks/useConfirmModal'
import { useMutation } from '@apollo/react-hooks'
import { GET_KEY_VALUES_QUERY } from '../../KeyValueList'

const REMOVE_KEY_VALUE = gql`
  mutation RemoveKeyValueForUser($email: String!, $_id: String!) {
    removeKeyValueForUser(email: $email, _id: $_id) {
      id
      keys {
        _id
        key
        value
      }
    }
  }
`

const ReadKeyValueForm = ({ userEmail, keyValue, onEdit, onFailure }) => {
  const [removeKeyValueMutation] = useMutation(REMOVE_KEY_VALUE, {
    refetchQueries: [
      {
        query: GET_KEY_VALUES_QUERY,
        variables: { email: userEmail },
      },
    ],
  })
  const removeKeyValue = () => {
    removeKeyValueMutation({
      variables: {
        email: userEmail,
        _id: keyValue._id,
      },
    }).then(response => {
      if (response.errors) {
        onFailure()
      }
    })
  }

  const [showConfirmModal] = useConfirmModal()

  return (
    <div className="key-value">
      <KeyValueForm
        keyValue={keyValue}
        readonly={true}
        onSubmit={onEdit}
        submitLabel={'Edit'}
        onClose={removeKeyValue}
      />
      <div className="button-group">
        <Button
          variant="primary"
          onClick={onEdit}
          className="button-group__button"
        >
          Edit
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            showConfirmModal(
              'Remove key-value',
              'Are you sure you want to remove key-value pair?',
              removeKeyValue
            )
          }}
          className="button-group__button"
        >
          Delete
        </Button>
      </div>
    </div>
  )
}

export { ReadKeyValueForm }
