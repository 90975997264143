import React from 'react'
import { Button, Form, Nav, Navbar } from 'react-bootstrap'
import { useLoggedUser, logoutUser } from '../../hooks/useLoggedUser'
import { routes } from '../../routes'
import { CustomNavItem } from './CustomNavItem'
import { useApolloClient } from '@apollo/react-hooks'

const MenuBar = () => {
  const [isLoggedIn, , isAdmin, userEmail] = useLoggedUser()
  const apolloClient = useApolloClient()

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <CustomNavItem
            to={routes.keyValuesForUser.createPath(userEmail)}
            label="Keys"
            disabled={!isLoggedIn}
          />
          <CustomNavItem
            to={routes.userList.createPath()}
            label="Users"
            disabled={!isAdmin || !isLoggedIn}
          />
        </Nav>
        <Form inline>
          {isLoggedIn ? (
            <Button
              variant="outline-secondary"
              onClick={() => {
                apolloClient.clearStore()
                logoutUser()
              }}
            >
              Logout
            </Button>
          ) : null}
        </Form>
      </Navbar.Collapse>
    </Navbar>
  )
}
export default MenuBar
