import { useLocalStorage } from './useLocalStorage'
import { useEffect, useState } from 'react'
import { setItem, removeItem } from './useLocalStorage'

const userKey = 'user'
let refreshJwtInterval = undefined

export const loginUser = user => {
  if (refreshJwtInterval) clearInterval(refreshJwtInterval)
  refreshJwtInterval = setInterval(() => {
    fetch('/refresh-jwt', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user.jwt || ''}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          response.json().then(value => {
            user.jwt = value
            setItem(userKey, user)
          })
        } else if (response.status === 401) {
          removeItem(userKey)
        }
      })
      .catch(reason => {
        removeItem(userKey)
      })
  }, 45 * 60 * 1000)
  setItem(userKey, user)
}

export const logoutUser = () => {
  if (refreshJwtInterval) clearInterval(refreshJwtInterval)
  removeItem(userKey)
}

const useLoggedUser = () => {
  const [user] = useLocalStorage('user', {})
  const [isLoggedIn, setIsLoggedIn] = useState(user && user.jwt && user.email)
  const [jwt, setJwt] = useState(user ? user.jwt : null)
  const [isAdmin, setIsAdmin] = useState(user ? user.isAdmin : false)
  const [userEmail, setEmail] = useState(user ? user.email : null)

  useEffect(() => {
    setIsLoggedIn(user && user.jwt && user.email)
    setJwt(user ? user.jwt : null)
    setIsAdmin(user ? user.isAdmin : false)
    setEmail(user ? user.email : null)
  }, [user])

  return [isLoggedIn, jwt, isAdmin, userEmail]
}

export { useLoggedUser }
