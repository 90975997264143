import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import LoadingIndicator from 'react-loading-indicator'
import { Alert, Button } from 'react-bootstrap'
import KeyValueEntry from './KeyValueEntry'
import { CreateKeyValueForm } from './forms/createKeyValue/CreateKeyValueForm'

export const GET_KEY_VALUES_QUERY = gql`
  query GetUser($email: String!) {
    getUser(email: $email) {
      id
      email
      keys {
        _id
        key
        value
      }
    }
  }
`

export const DUMMY_KEY_VALUE_PREFIX = 'dummy_key_value_'

const KeyValueList = ({ userEmail }) => {
  const [isCreating, setCreating] = useState(false)
  const { loading, error, data } = useQuery(GET_KEY_VALUES_QUERY, {
    variables: { email: userEmail },
  })

  const createNewKeyValue = () => {
    setCreating(true)
  }

  const closeCreating = () => setCreating(false)

  if (loading || !userEmail) return <LoadingIndicator />
  if (error)
    return <Alert variant={'danger'}>'Error during data loading.'</Alert>

  const keyValues = data.getUser ? data.getUser.keys : []

  return (
    <React.Fragment>
      {keyValues.map(keyValue => {
        return (
          <KeyValueEntry
            key={keyValue._id}
            keyValue={keyValue}
            userEmail={userEmail}
          />
        )
      })}
      {isCreating ? (
        <CreateKeyValueForm
          userEmail={userEmail}
          onSuccess={closeCreating}
          onClose={closeCreating}
        />
      ) : (
        <Button variant="success" onClick={createNewKeyValue}>
          Add new key-value
        </Button>
      )}
    </React.Fragment>
  )
}

export default KeyValueList
