import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConfirmModalProvider } from './containers/modals/ConfirmModalContext'
import { getItem } from './hooks/useLocalStorage'
import { loginUser } from './hooks/useLoggedUser'
import apolloClient from './singletons/apolloClient'
import { ApolloProvider } from 'react-apollo'

const renderApp = () => {
  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <Router>
        <ConfirmModalProvider>
          <App />
        </ConfirmModalProvider>
      </Router>
    </ApolloProvider>,
    document.getElementById('root')
  )
}

const user = getItem('user')
if (user && user.jwt) loginUser(user)

renderApp()
