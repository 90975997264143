import React from 'react'
import { Form } from 'react-final-form'
import { Button } from 'react-bootstrap'
import { CustomField } from '../customField/CustomFIeld'
import './KeyValueForm.css'
import '../Forms.css'

const KeyValueForm = ({
  keyValue,
  readonly,
  onSubmit,
  onClose,
  submitLabel,
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ key: keyValue.key, value: keyValue.value }}
      render={({ handleSubmit, submitting, pristine, valid }) => (
        <form onSubmit={handleSubmit} className="key-value-form">
          <CustomField
            name="key"
            type="text"
            placeholder="Enter key"
            disabled={readonly}
            onValidate={value => (value ? undefined : 'Key is required field!')}
          />
          <CustomField
            name="value"
            type="text"
            placeholder="Enter value"
            disabled={readonly}
            onValidate={value =>
              value ? undefined : 'Value is required field!'
            }
          />
          {!readonly && (
            <div className="button-group">
              <Button
                className="button-group__button"
                variant="success"
                type="submit"
                disabled={submitting || pristine || !valid}
              >
                {submitLabel}
              </Button>
              <Button
                className="button-group__button"
                variant="secondary"
                hidden={readonly}
                onClick={onClose}
              >
                Close
              </Button>
            </div>
          )}
        </form>
      )}
    />
  )
}

export default KeyValueForm
