import { useState, useEffect, useCallback } from 'react'

const eventName = 'localStorageChanged_'

const isSupported = () => {
  try {
    const testKey = '__some_random_key_you_are_not_going_to_use__'
    localStorage.setItem(testKey, testKey)
    localStorage.removeItem(testKey)
    return true
  } catch (e) {
    return false
  }
}

const dispatchValueChangedEvent = (key, value) => {
  window.dispatchEvent(new CustomEvent(eventName + key, { detail: { value } }))
}

export const setItem = (key, value) => {
  if (isSupported()) localStorage.setItem(key, JSON.stringify(value))
  dispatchValueChangedEvent(key, value)
}

export const getItem = key => {
  if (isSupported()) {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch (e) {
      return undefined
    }
  }
  return null
}

export const removeItem = key => {
  if (isSupported()) localStorage.removeItem(key)
  dispatchValueChangedEvent(key, null)
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(getItem(key) || defaultValue)

  const onValueChange = useCallback(event => {
    setValue(event.detail.value)
  }, [])

  useEffect(() => {
    window.addEventListener(eventName + key, onValueChange)
    return () => window.removeEventListener(eventName + key, onValueChange)
  }, [key, onValueChange])

  const saveValue = value => {
    setValue(value)
    setItem(key, value)
  }

  const removeValue = () => {
    setValue(null)
    removeItem(key)
  }

  return [value, saveValue, removeValue]
}
