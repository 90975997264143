import { ApolloClient } from 'apollo-client'
import { ApolloLink, Observable } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { getItem, removeItem } from '../hooks/useLocalStorage'

const httpLink = createHttpLink()

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const user = getItem('user')
  const token = user ? user.jwt : null

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ response, networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    removeItem('user')
    // workaround https://github.com/apollographql/apollo-link/issues/855#issuecomment-485764697
    return Observable.of()
  }
})

const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
})

export default apolloClient
