import React from 'react'
import { NavItem } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import './MenuBar.css'

const CustomNavItem = ({ to, label, disabled }) => {
  return (
    <NavItem>
      {!disabled ? (
        <NavLink to={to}>{label}</NavLink>
      ) : (
        <span className="span--disabled">{label}</span>
      )}
    </NavItem>
  )
}

export { CustomNavItem }
