import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export const ConfirmModalRoot = ({
  isOpened,
  onClose,
  onConfirm,
  headerText,
  bodyText,
}) => (
  <Modal show={isOpened} onHide={onClose}>
    <Modal.Header closeButton>
      <Modal.Title>{headerText}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{bodyText}</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        Close
      </Button>
      <Button variant="primary" onClick={onConfirm}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
)
