import React, { useState } from 'react'
import { ReadKeyValueForm } from './forms/readKeyValue/ReadKeyValueForm'
import { UpdateKeyValueForm } from './forms/updateKeyValue/UpdateKeyValuesForm'
import { Alert } from 'react-bootstrap'

const READ = 'read'
const UPDATE = 'update'

const KeyValueEntry = ({ keyValue, userEmail }) => {
  const [entryState, setEntryState] = useState(READ)
  const [error, setError] = useState()

  const onReadFormEdit = () => {
    setEntryState(UPDATE)
  }

  const onUpdateFormClose = () => {
    setEntryState(READ)
  }

  return (
    <React.Fragment>
      {error ? <Alert variant="danger">{error}</Alert> : null}
      {(() => {
        switch (entryState) {
          case READ:
            return (
              <ReadKeyValueForm
                key={keyValue._id}
                keyValue={keyValue}
                userEmail={userEmail}
                onEdit={onReadFormEdit}
                onFailure={() => setError('Error during remove.')}
              />
            )
          case UPDATE:
            return (
              <UpdateKeyValueForm
                key={keyValue._id}
                keyValue={keyValue}
                userEmail={userEmail}
                onSuccess={keyValue => {
                  onUpdateFormClose()
                }}
                onClose={onUpdateFormClose}
                onFailure={() => setError('Error during update.')}
              />
            )
          default:
            return null
        }
      })()}
    </React.Fragment>
  )
}

export default KeyValueEntry
