import React, { useState, useRef } from 'react'
import { ConfirmModalRoot } from './ConfirmModalRoot'

export const ConfirmModalContext = React.createContext({
  showModal: () => {},
})

export const ConfirmModalProvider = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false)
  const [header, setHeader] = useState()
  const [body, setBody] = useState()
  const defferedPromise = useRef()

  const showModal = (headerText, bodyText) => {
    setHeader(headerText)
    setBody(bodyText)
    setIsOpened(true)
    return new Promise((resolve, reject) => {
      defferedPromise.current = { resolve, reject }
    })
  }

  const onConfirm = () => {
    setIsOpened(false)
    defferedPromise.current.resolve()
  }

  const onClose = () => {
    setIsOpened(false)
    defferedPromise.current.reject()
  }

  return (
    <ConfirmModalContext.Provider value={{ showModal }}>
      <React.Fragment>
        {children}
        <ConfirmModalRoot
          isOpened={isOpened}
          onClose={onClose}
          onConfirm={onConfirm}
          headerText={header}
          bodyText={body}
        />
      </React.Fragment>
    </ConfirmModalContext.Provider>
  )
}
