import React from 'react'
import KeyValueList from './KeyValueList'
import { Col, Row } from 'react-bootstrap'
import { useLoggedUser } from '../../hooks/useLoggedUser'
import UserSelect from './userSelect'
import { withRouter } from 'react-router'

const KeyValuesPage = props => {
  const [, , isAdmin] = useLoggedUser()
  const user = props.match.params.email

  return (
    <Row>
      <Col xs={12}>
        {isAdmin ? <UserSelect /> : null}
        {user && <KeyValueList userEmail={user} />}
      </Col>
    </Row>
  )
}

export default withRouter(KeyValuesPage)
