import React from 'react'
import KeyValueForm from '../keyValueForm/KeyValueForm'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { GraphqlErrors } from '../../../GraphqlErrors'

const UPDATE_KEY_VALUE = gql`
  mutation EditKeyValueForUser(
    $email: String!
    $_id: String!
    $keyValue: KeyValueInput!
  ) {
    editKeyValueForUser(email: $email, _id: $_id, keyValue: $keyValue) {
      id
      keys {
        _id
        key
        value
      }
    }
  }
`

const UpdateKeyValueForm = ({ userEmail, keyValue, onSuccess, onClose }) => {
  const [updateKeyValue, { error }] = useMutation(UPDATE_KEY_VALUE)
  return (
    <React.Fragment>
      <GraphqlErrors error={error} />
      <div className="key-value">
        <KeyValueForm
          keyValue={keyValue}
          readonly={false}
          onSubmit={values => {
            return updateKeyValue({
              variables: {
                email: userEmail,
                _id: keyValue._id,
                keyValue: values,
              },
            })
              .then(response => {
                onSuccess(
                  response.data.editKeyValueForUser.keys.find(
                    kv => kv._id === keyValue._id
                  )
                )
              })
              .catch(err => {})
          }}
          submitLabel={'Save'}
          onClose={onClose}
        />
      </div>
    </React.Fragment>
  )
}

export { UpdateKeyValueForm }
