import React from 'react'
import { Field, Form } from 'react-final-form'
import { Alert, Button, ButtonToolbar } from 'react-bootstrap'
import { withRouter } from 'react-router'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import './UpdateUserForm.css'
import { routes } from '../../routes'
import withProgress from '../../withProgress'
import { graphql } from 'react-apollo'
import { compose } from 'recompose'

const GET_USER_QUERY = gql`
  query GetUser($email: String!) {
    getUser(email: $email) {
      id
      email
      fullName
      isAdmin
    }
  }
`

const UPDATE_USER_MUTATION = gql`
  mutation EditUser($email: String!, $user: UserInput!) {
    editUser(email: $email, user: $user) {
      id
      email
      fullName
      isAdmin
    }
  }
`

const UpdateUserForm = props => {
  const [updateUser, { error: mutationError }] = useMutation(
    UPDATE_USER_MUTATION
  )

  const editUser = values => {
    return updateUser({
      variables: {
        email: values.email,
        user: { fullName: values.fullName, isAdmin: values.isAdmin },
      },
    }).then(() => {
      onClose()
    })
  }
  const onClose = () => {
    props.history.push(routes.userList.createPath())
  }
  if (mutationError)
    return <Alert variant={'danger'}>'Error during data saving.'</Alert>

  return (
    <Form
      onSubmit={editUser}
      initialValues={{
        email: props.data.getUser.email,
        fullName: props.data.getUser.fullName,
        isAdmin: props.data.getUser.isAdmin,
      }}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit} className="update-user">
          <div>
            <label>Email</label>
            <Field name="email" component="input" type="text" disabled={true} />
          </div>
          <div>
            <label>Full Name</label>
            <Field
              name="fullName"
              component="input"
              type="text"
              placeholder="Full Name"
            />
          </div>
          <div>
            <label>Is admin?</label>
            <Field name="isAdmin" component="input" type="checkbox" />
          </div>
          <ButtonToolbar className="spacing">
            <Button
              variant="success"
              type="submit"
              disabled={submitting || pristine}
            >
              Save changes
            </Button>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
          </ButtonToolbar>
        </form>
      )}
    />
  )
}

export default compose(
  graphql(GET_USER_QUERY, {
    options: ownProps => ({
      variables: {
        email: ownProps.match.params.email,
      },
    }),
  }),
  withProgress({ errorMessage: 'Error during loading data.' })
)(withRouter(UpdateUserForm))
