import React from 'react'
import { gql } from 'apollo-boost'
import { graphql } from 'react-apollo'
import { compose } from 'recompose'
import { useMutation } from '@apollo/react-hooks'
import {
  Alert,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Row,
  Table,
} from 'react-bootstrap'
import { withRouter } from 'react-router'
import { useModal } from '../../hooks/useModal'
import NewUserModal from '../modals/NewUserModal'
import './UserList.css'
import { routes } from '../../routes'
import withProgress from '../../withProgress'
import { useConfirmModal } from '../../hooks/useConfirmModal'

const GET_USERS_QUERY = gql`
  query GetUsers {
    getUsers {
      id
      email
      fullName
      isAdmin
      keys {
        _id
        key
        value
      }
    }
  }
`

const REMOVE_USER_QUERY = gql`
  mutation RemoveUser($email: String!) {
    removeUser(email: $email) {
      success
    }
  }
`

const CREATE_USER_MUTATION = gql`
  mutation CreateUser($email: String!) {
    createUser(email: $email) {
      id
      email
      fullName
      isAdmin
      keys {
        _id
        key
        value
      }
    }
  }
`

const UserList = props => {
  const [remove, { error: removeMutationError }] = useMutation(
    REMOVE_USER_QUERY,
    {
      update(
        cache,
        {
          data: { removeUser },
        }
      ) {
        if (removeUser.success) {
          const { getUsers } = cache.readQuery({ query: GET_USERS_QUERY })
          getUsers.splice(
            getUsers.findIndex(u => u.email === removeUser.removedUser),
            1
          )
          cache.writeQuery({
            query: GET_USERS_QUERY,
            data: { getUsers: getUsers },
          })
        }
      },
    }
  )
  const [createUserMutation, { error: createMutationError }] = useMutation(
    CREATE_USER_MUTATION,
    {
      update(
        cache,
        {
          data: { createUser },
        }
      ) {
        const { getUsers } = cache.readQuery({ query: GET_USERS_QUERY })
        getUsers.push(createUser)
        cache.writeQuery({
          query: GET_USERS_QUERY,
          data: { getUsers: getUsers },
        })
      },
    }
  )

  const [showConfirmModal] = useConfirmModal()
  const userModal = useModal()

  const createUser = email => {
    return createUserMutation({ variables: { email: email } }).then(() =>
      userModal.close()
    )
  }

  const onRemove = user => {
    showConfirmModal(
      "Remove user '" + user.email + "'?",
      "You are about to remove user '" +
        user.email +
        "' and all associated keys. Are you sure you want to remove user?",
      () => remove({ variables: { email: user.email } })
    )
  }

  const onEdit = user => {
    props.history.push(routes.userDetail.createPath(user.email))
  }

  const onShowKeys = user => {
    props.history.push(routes.keyValuesForUser.createPath(user.email))
  }

  return (
    <Row>
      <Col xs={12}>
        {removeMutationError ? (
          <Alert variant={'danger'}>Error during removing user.</Alert>
        ) : null}

        {createMutationError ? (
          <Alert variant={'danger'}>Error during creating user.</Alert>
        ) : null}

        <h2>Users</h2>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Email</th>
              <th>Name</th>
              <th>Number of Keys</th>
              <th>Is admin</th>
              <th>Controls</th>
            </tr>
          </thead>
          <tbody>
            {(props.data.getUsers || []).map(user => (
              <tr key={user.id}>
                <td>{user.email}</td>
                <td>{user.fullName}</td>
                <td>{(user.keys || []).length}</td>
                <td>{user.isAdmin ? 'Yes' : 'No'}</td>
                <td>
                  <ButtonToolbar className="spacing">
                    <Button variant="info" onClick={() => onEdit(user)}>
                      Edit
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => onShowKeys(user)}
                    >
                      Show Keys
                    </Button>
                    <Button variant="danger" onClick={() => onRemove(user)}>
                      Delete
                    </Button>
                  </ButtonToolbar>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ButtonGroup>
          <Button variant="success" onClick={userModal.open}>
            Add new user
          </Button>
        </ButtonGroup>
        <NewUserModal
          isOpened={userModal.isShowing}
          onClose={userModal.close}
          onConfirm={createUser}
        />
      </Col>
    </Row>
  )
}
export default compose(
  graphql(GET_USERS_QUERY),
  withProgress({ errorMessage: 'Error during loading data.' })
)(withRouter(UserList))
