import React, { useEffect } from 'react'
import LoginPage from './containers/LoginPage'
import { Redirect, Route } from 'react-router-dom'
import UserList from './containers/users/UserList'
import MenuBar from './containers/menu/MenuBar'
import { Container } from 'react-bootstrap'
import KeyValuesPage from './containers/keyValues/KeyValuesPage'
import UpdateUserForm from './containers/users/UpdateUserForm'
import { routes } from './routes'
import { useLoggedUser } from './hooks/useLoggedUser'
import { withRouter } from 'react-router-dom'

const App = props => {
  const [isLoggedIn, , isAdmin, userEmail] = useLoggedUser()

  useEffect(() => {
    if (isLoggedIn) {
      if (isAdmin) props.history.push(routes.userList.createPath())
      else props.history.push(routes.keyValuesForUser.createPath(userEmail))
    } else props.history.push(routes.loginPage.createPath())
  }, [userEmail, isAdmin, isLoggedIn, props.history])

  return (
    <Container>
      <MenuBar />
      <Redirect from={routes.root.route} to={routes.loginPage.route} />
      <Route path={routes.loginPage.route} component={LoginPage} />
      <Route
        exact
        path={routes.keyValuesForUser.route}
        component={KeyValuesPage}
      />
      <Route
        exact
        path={routes.keyValuesPage.route}
        component={KeyValuesPage}
      />
      <Route path={routes.userList.route} component={UserList} />
      <Route path={routes.userDetail.route} component={UpdateUserForm} />
    </Container>
  )
}

export default withRouter(App)
