import { useContext } from 'react'
import { ConfirmModalContext } from '../containers/modals/ConfirmModalContext'

export const useConfirmModal = () => {
  const context = useContext(ConfirmModalContext)

  const show = (headerText, bodyText, onConfirm) => {
    context
      .showModal(headerText, bodyText)
      .then(onConfirm)
      .catch(() => {})
  }

  return [show]
}
