import React from 'react'
import KeyValueForm from '../keyValueForm/KeyValueForm'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { GET_KEY_VALUES_QUERY } from '../../KeyValueList'
import { GraphqlErrors } from '../../../GraphqlErrors'

const CREATE_KEY_VALUE = gql`
  mutation AddKeyValueForUser($email: String!, $keyValue: KeyValueInput!) {
    addKeyValueForUser(email: $email, keyValue: $keyValue) {
      id
      keys {
        _id
        key
        value
      }
    }
  }
`

const CreateKeyValueForm = ({ userEmail, onSuccess, onClose }) => {
  const [createKeyValue, { error }] = useMutation(CREATE_KEY_VALUE, {
    refetchQueries: [
      {
        query: GET_KEY_VALUES_QUERY,
        variables: { email: userEmail },
      },
    ],
  })
  return (
    <React.Fragment>
      <GraphqlErrors error={error} />
      <div className="key-value">
        <KeyValueForm
          keyValue={{}}
          readonly={false}
          onSubmit={values => {
            return createKeyValue({
              variables: {
                email: userEmail,
                keyValue: values,
              },
            })
              .then(response =>
                onSuccess(response.data.addKeyValueForUser.keys)
              )
              .catch(err => {})
          }}
          submitLabel={'Create'}
          onClose={onClose}
        />
      </div>
    </React.Fragment>
  )
}
export { CreateKeyValueForm }
