import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import './NewUserModal.css'

const NewUserModal = ({ isOpened, onClose, onConfirm }) => {
  const onSubmit = values => {
    return onConfirm(values.email)
  }
  return (
    <Modal show={isOpened} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add new user</Modal.Title>
      </Modal.Header>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit} className="new-user-modal">
            <Modal.Body>
              <div>
                <label>Email</label>
                <Field name="email" component="input" type="text" />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onClose}>
                Close
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={submitting || pristine}
              >
                {submitting ? 'Creating...' : 'Confirm'}
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  )
}

export default NewUserModal
