import React from 'react'
import { gql } from 'apollo-boost'
import { withRouter } from 'react-router'
import { routes } from '../../routes'
import withProgress from '../../withProgress'
import { graphql } from 'react-apollo'
import { compose } from 'recompose'

const GET_USERS_QUERY = gql`
  query GetUsers {
    getUsers {
      id
      email
      fullName
    }
  }
`

const UserSelect = props => {
  const user = props.match.params.email

  const onSelectChange = event => {
    const selectedUser = event.target.value
    props.history.push(routes.keyValuesForUser.createPath(selectedUser))
  }

  return (
    <div>
      <h3>Select user:</h3>
      <select value={user} onChange={onSelectChange}>
        <option key={'emptyOption'} />
        {(props.data.getUsers || []).map(user => (
          <option key={user.email} value={user.email}>
            {user.fullName}
          </option>
        ))}
      </select>
    </div>
  )
}

export default compose(
  graphql(GET_USERS_QUERY),
  withProgress({ errorMessage: 'Error during data loading.' })
)(withRouter(UserSelect))
