import React from 'react'
import { Field } from 'react-final-form'
import './CustomField.css'

const CustomField = ({
  label,
  name,
  type,
  placeholder,
  disabled,
  onValidate,
}) => (
  <Field name={name} validate={onValidate}>
    {({ input, meta }) => (
      <div className="custom-field">
        {label && <label className="custom-field__label">{label}</label>}
        <input
          {...input}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          className={
            'custom-field__input ' +
            (meta.error && meta.touched ? 'custom-field__input--error' : '')
          }
        />
      </div>
    )}
  </Field>
)

export { CustomField }
