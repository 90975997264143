import React from 'react'
import { Alert } from 'react-bootstrap'

export const GraphqlErrors = ({ error }) => {
  return error && error.graphQLErrors
    ? error.graphQLErrors.map(({ message }, i) => (
        <Alert key={i} variant="danger">
          {message}
        </Alert>
      ))
    : null
}
