import React, { useState } from 'react'
import { GoogleLogin } from 'react-google-login'
import { gql } from 'apollo-boost'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { Col, Row } from 'react-bootstrap'
import { loginUser, logoutUser } from '../hooks/useLoggedUser'

const LOGIN_QUERY = gql`
  mutation LoginUser($tokenId: String!) {
    loginUser(tokenId: $tokenId) {
      success
      payload
      message
    }
  }
`

const GET_USER_QUERY = gql`
  query GetUser($email: String!) {
    getUser(email: $email) {
      email
      isAdmin
    }
  }
`

const LoginPage = () => {
  const [loginUserMutation] = useMutation(LOGIN_QUERY)
  const apolloClient = useApolloClient()
  const [error, setError] = useState('')

  const onGoogleSuccess = response => {
    const tokenId = response.tokenObj.id_token
    const email = response.profileObj.email
    loginUserMutation({
      variables: { tokenId },
    }).then(response => {
      if (response.data.loginUser.success) {
        const jwt = response.data.loginUser.payload
        loginUser({
          jwt,
        })
        apolloClient
          .query({
            query: GET_USER_QUERY,
            variables: { email: email },
          })
          .then(getUserResult => {
            const user = getUserResult.data.getUser
            loginUser({
              jwt: jwt,
              email: user.email,
              isAdmin: user.isAdmin,
            })
          })
          .catch(err => {
            logoutUser()
            setError('Error during loading user profile.')
          })
      } else {
        logoutUser()
        setError(response.data.loginUser.message)
      }
    })
  }

  const onGoogleFailure = response => {
    console.log(response)
    setError('Cannot login with Google. Try again later, please.')
  }

  return (
    <Row>
      <Col xs={12}>
        {error ? <div className={'alert alert-danger'}> {error} </div> : null}
        <Row>
          <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
            <GoogleLogin
              clientId={process.env.REACT_APP_CLIENT_ID}
              buttonText="Login with Google"
              onSuccess={onGoogleSuccess}
              onFailure={onGoogleFailure}
              cookiePolicy={'single_host_origin'}
              hostedDomain={process.env.REACT_APP_HOSTED_DOMAIN || ''}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default LoginPage
