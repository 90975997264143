import React from 'react'
import LoadingIndicator from 'react-loading-indicator'
import { Alert } from 'react-bootstrap'

export default function withProgress(opts) {
  return Component => {
    return props => {
      const { loading, error } = props.data || {}

      if (loading) {
        return <LoadingIndicator />
      }

      if (error) {
        return <Alert variant="danger">{opts.errorMessage}</Alert>
      }

      return <Component {...props} />
    }
  }
}
