const routes = {
  root: { route: '/', createPath: () => '/' },
  loginPage: {
    route: '/login',
    createPath: () => '/login',
  },
  keyValuesPage: {
    route: '/keyValues',
    createPath: () => '/keyValues',
  },
  keyValuesForUser: {
    route: '/keyValues/:email',
    createPath: email => '/keyValues/' + email,
  },
  userList: {
    route: '/users',
    createPath: () => '/users',
  },
  userDetail: {
    route: '/user/:email',
    createPath: email => '/user/' + email,
  },
}

export { routes }
